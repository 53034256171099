// settings
import Settings from '@/config/Settings';

// components
import Layout from '@/Views/_Layout/index.vue';
import Header from '@/Views/_Components/Header/Default/index.vue';
import Links from '@/Views/_Components/Links/index.vue';
import HelpMenu from '@/Views/_Components/HelpMenu/index.vue';
import Banner from '@/Views/_Components/Banner/index.vue';
import Magazines from '@/Views/_Components/Magazines/index.vue';
// import Highlights from '@/Views/_Components/Highlights/index.vue';

import LoadImage from '@/Views/_Components/Helpers/Image/index.vue';

export default {
	name: 'Home',

	components: {
		'oston-layout': Layout,
		'oston-header': Header,
		'oston-help-menu': HelpMenu,
    'oston-banner': Banner,
    'oston-links': Links,
    'oston-magazines': Magazines,
		// 'oston-highlights': Highlights,

    // helpers
    'oston-image': LoadImage
	},

	mounted() {
		Settings.title('Home');
  },
};
