import {mapGetters} from 'vuex';0


export default {
  name: "Banner",

  mounted() {
    _.controller('magazine').getBanner();
    console.log(this.banner)
  },

  computed: {
    ...mapGetters({
      banner: 'MagazineModel/banner'
    })
  }
}
