// helpers
import Image from '@/Views/_Components/Helpers/Image/index.vue';

// icons
import { MenuIcon, SearchIcon } from 'vue-feather-icons'

export default {
  name: 'OstonHeader',
  components: {
    // helpers
    'oston-image': Image,

    'feather-icon-menu': MenuIcon,
    'feather-icon-search': SearchIcon
  },

  methods: {
    togglr: function () {
      _.controller('menu').open()
    }
  }
}
