// core
import { mapGetters } from 'vuex';

// components
import LoadImage from '@/Views/_Components/Helpers/Image/index.vue';

// helpers
import { slugify } from '@/Helpers/Misc';

export default {
	name: 'Links',

	components: {
		'oston-image': LoadImage
	},

	mounted() {
		_.controller('Category').getAll();
	},

	methods: {
		slugify,

		select: function(id, title) {
      _.controller('category').set(title);
      _.controller('category').get(id);
		}
	},

	computed: mapGetters({
		links: 'CategoryModel/categories'
	})
};
